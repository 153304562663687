<template>
  <div style="padding: 30vh 20px;
  display: flex;
  align-items: center;
  justify-content: center;">
    <button
        v-if="isButtonVisible"
        class="button button-white button-white--big"
        @click="triggerQrScan"
        style="width: 100%;"
    >
      Загрузить ещё чек
    </button>

  </div>
</template>

<script>
import {
  // getUserInfo
  // getUserInfo,
  // setAuthHeader,
  // getToken,
  // authorize
} from "@/api";
import metrikaMixin from '@/mixins/metrikaMixin';

export default {
  name: 'TGUploadCheck',
  mixins: [metrikaMixin],
  data() {
    return {
      isButtonVisible: false,
      password: '',
      password_check: '',
      loginFormErrors: {},
      passwordVisible: false,
      rootUrl: window.location.origin,
      prizes: [],
      isOpen: [],
      errors: {},
      showAccountDetailsPopup: false,
      showAccountChangePasswordPopup: false,
      showChoizePrizePopup: false,
      showAccountChangePasswordSuccess: false,
      showAccountDetailsSuccess: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {},
      responseError: '',
      checkOffset: 0,
      checkLimit: 20,
    }
  },
  mounted() {
    setTimeout(() => {
      this.triggerQrScan();

      setTimeout(() => {
        this.isButtonVisible = true;
      }, 1500);
    }, 1200);
  },
  methods: {
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
  },
};
</script>

<style>
/* Ваши стили */
</style>