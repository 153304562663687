<template>
  <div>
    <section id="account">
      <div class="section-title">
        Личный кабинет
      </div>
      <div class="mini-section-title">
        Личные данные
      </div>
      <div class="personal-data">
        <div class="personal-details">
          <form @submit.prevent="changeAccountDetails" autocomplete="off">
            <div class="personal-details-row">
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-name">Имя</label>
                    <input type="text" maxlength="255" id="reg-name" v-model="accountUser.first_name" @input="removeSpacesFirstName" name="first_name"
                           :class="{ 'is-error': errors.first_name }" disabled>
                    <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
                  </div>
                </div>
              </div>
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="last-name">Фамилия</label>
                    <input type="text" maxlength="255" id="last-name" v-model="accountUser.last_name" @input="removeSpacesLastName" name="last_name"
                           :class="{ 'is-error': errors.last_name }" disabled>
                    <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personal-details-row">
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-email">Email</label>
                    <input type="text" maxlength="255" id="reg-email" v-model="accountUser.email" name="email"
                           :class="{ 'is-error': errors.email }" disabled>
                    <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
                  </div>
                </div>
              </div>
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-email">Телефон</label>
                    <input type="tel" maxlength="255" id="reg-email" v-model="accountUser.phone_number" name="tel" disabled>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </section>

    <section id="account-prizes">
      <div class="mini-section-title">
        Мои призы
      </div>
      <div class="winning-prizes">
        <div v-if="prizes.length > 0" class="prizes-list" data-cols="6">
          <div v-for="(prize, index) in prizes" :key="index" class="prizes-list-item">
            <div class="prizes-list-item__img">
              <a v-if="prize.ticket" :href="prize.ticket" target="_blank" class="prizes-list-item__link" title="Скачать билет"></a>
              <img :src="getImageSrc(prize.cabinet_image)" loading="lazy" />
            </div>
            <div class="prizes-list-item__title">
              <span>
                {{ prize.title }}
                <span v-if="prize.greeting_text" class="tooltip-icon" :data-tooltip="prize.greeting_text"></span>
              </span>
            </div>
          </div>

          <div v-for="(check, index) in approvedChecks" :key="index" class="prizes-list-item prizes-list-item--garant" @click="openChoicePrizePopup(check.id)">
            <div class="prizes-list-item__img">
              <img src="@/assets/img/main/garant-img.png"
                   srcset="@/assets/img/main/garant-img@2x.png 2x" alt="">
              <div class="prizes-list-item__choice">Выбрать</div>
            </div>
            <div class="prizes-list-item__title">
              Выберите
              <br>гарантированный приз
            </div>
          </div>
        </div>

        <div v-else-if="approvedChecks.length > 0" class="prizes-list" data-cols="6">
          <div v-for="(check, index) in approvedChecks" :key="index" class="prizes-list-item prizes-list-item--garant" @click="openChoicePrizePopup(check.id)">
            <div class="prizes-list-item__img">
              <img src="@/assets/img/main/garant-img.png"
                   srcset="@/assets/img/main/garant-img@2x.png 2x" alt="">
              <div class="prizes-list-item__choice">Выбрать</div>
            </div>
            <div class="prizes-list-item__title">
              Выберите
              <br>гарантированный приз
            </div>
          </div>
        </div>

        <div v-else class="empty">
          <div class="empty__text">
            Чтобы получить приз прими участие в акции с 15 сентября по 30 ноября, в магазинах
            <br>расположенных на территории Москвы и Московской области
          </div>
          <div class="empty__btns">
            <button class="button button-rose" @click="triggerQrScan">Загрузить чек</button>
            <a href="https://t.me/UglechePole_bot" class="button button-black" rel="_nofollow" target="_blank" @click="handleChatBotButtonClick()">Перейти в чат-бот</a>
          </div>
        </div>

      </div>
    </section>

    <section id="account-checks">
      <div class="mini-section-title">
        Загруженные чеки
        <button v-if="checks.results.length > 0" class="button button-rose" @click="triggerQrScan">Загрузить чек</button>
      </div>
      <div class="users-check">

        <div class="empty" v-if="checks.results.length === 0">
          <div class="empty__text">
            Вы не загрузили ни одного чека.
          </div>
          <div class="empty__btns">
            <button class="button button-rose" @click="triggerQrScan">Загрузить чек</button>
          </div>
        </div>

        <div class="users-check-list" v-else>
          <div v-for="(check, cIndex) in checks.results" :key="check.id" :class="['check-item', getStatusClass(check.status)]">
            <div class="check-item__status">
              {{ getStatusText(check.status) }}
              <span v-if="check.reject_reason" :data-tooltip="check.reject_reason" class="tooltip-icon"></span>
            </div>

            <div class="check-item__info"></div>

            <div v-if="check.images" class="check-item__img">
              <a v-for="(image, index) in check.images" :key="index" :href="getImageSrc(image)" :data-fancybox="'user-check-' + cIndex">
                <img :src="getImageSrc(image)" />
                <button class="button">

                </button>
              </a>
            </div>

            <div class="check-item__date">
              <span>{{ formatDate(check.upload_date) }}</span>
              <span>{{ formatTime(check.upload_date) }}</span>
            </div>

          </div>
          <div v-if="checks.next" class="load-more-checks">
            <button class="button button-white" @click="loadMoreChecks">Загрузить ещё</button>
          </div>
        </div>

      </div>
    </section>

    <!-- Popup для изменения данных -->
    <div v-if="showAccountDetailsSuccess" class="popup">
      <div class="popup__overlay" @click="closeAccountDetailsSuccess"></div>
      <div class="popup__window">
        <button @click="closeAccountDetailsSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваши данные успешно обновлены
          </div>
        </div>
      </div>
    </div>


    <!-- Popup для изменения пароля -->
    <div v-if="showAccountChangePasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountChangePasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountChangePasswordPopup" class="popup__close"></button>
        <div class="popup__title">Изменить пароль</div>
        <div class="popup__content">
          <form @submit.prevent="changeAccountPassword" autocomplete="off">
            <!-- Поле пароля -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password"
                    v-model="password"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
              </div>
            </div>
            <!-- Поле подтверждения пароля -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Подтверждение пароля</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password_check"
                    v-model="password_check"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password_check }}<br></span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-green button-green--big"
                        :disabled="!isFormValid">
                  <span>Изменить пароль</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showAccountChangePasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeAccountChangePasswordSuccess"></div>
      <div class="popup__window">
        <button @click="closeAccountChangePasswordSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваш пароль успешно обновлён
          </div>
        </div>
      </div>
    </div>


    <div v-if="showChoizePrizePopup" class="popup">
      <div class="popup__overlay" @click="closeChoizePrizePopup"></div>
      <div class="popup__window popup__window--garant">
        <button @click="closeChoizePrizePopup" class="popup__close"></button>
        <div class="popup__title">Выберите гарантированный приз</div>
        <div class="popup__content">
          <div class="garant-prizes-choice">
            <div
                v-for="prize in garantPrizes"
                :key="prize.id"
                class="garant-prizes-choice__item"
                :class="{ 'selected': selectedPrizeId && selectedPrizeId.id === prize.id }"
                @click="selectPrize(prize)"
            >
              <div class="garant-prizes-choice__item__img">
                <img :src="prize.cabinet_image" :alt="prize.title">
              </div>
              <div class="garant-prizes-choice__item__name">
                <span>{{ prize.title }}</span>
                <span v-if="prize.greeting_text" class="tooltip-icon" :data-tooltip="prize.greeting_text"></span>
              </div>
            </div>
          </div>
          <div class="garant-prizes-choice__btn">
            <button
                class="button button-green button-green--big"
                :disabled="!selectedPrizeId"
                @click="confirmPrize"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getPrizes,
  patchReceiptPrize,
  getMainSettings,
  getToken,
  getUserInfo,
  changeProfile,
  setAuthHeader,
  getWinningPrizes,
  getUserChecks,
  changePassword,
  getAllUserChecks
} from "@/api";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import metrikaMixin from '@/mixins/metrikaMixin';

export default {
  name: 'TGPrivatePage',
  mixins: [metrikaMixin],
  data() {
    return {
      guaranteedPrizes: [],
      selectedPrizeId: null,
      currentCheckId: null,
      password: '',
      password_check: '',
      loginFormErrors: {},
      passwordVisible: false,
      rootUrl: window.location.origin,
      garantPrizes: [],
      prizes: [],
      isOpen: [],
      errors: {},
      showAccountDetailsPopup: false,
      showAccountChangePasswordPopup: false,
      showChoizePrizePopup: false,
      showAccountChangePasswordSuccess: false,
      showAccountDetailsSuccess: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {},
      responseError: '',
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      accountDetailsForm: {
        first_name: '',
        last_name: '',
        email: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      allChecks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 20,
      statusTextMap: {
        0: 'На модерации',
        1: 'На проверке',
        3: 'Одобрен',
        4: 'Отклонен',
        5: 'Выиграл',
      },
      accountSections: [
        { name: 'Личные данные', component: 'PersonalData', scrolled: false },
        { name: 'Выигрынные призы', component: 'WinningPrizes', scrolled: false},
        { name: 'Загруженные чеки', component: 'QuizSection', scrolled: false },
      ],
    }
  },
  mounted() {
    this.fetchUsersCheck();
    this.fetchAllUsersCheck();
    Fancybox.bind("[data-fancybox]", {
    });
    this.initializeOpenState();
    this.fetchWinningPrizes();
    setAuthHeader();
    const urlParams = new URLSearchParams(window.location.search);
    const tokenURL = urlParams.get('token');
    let token = '';
    if (tokenURL) {
      token = tokenURL;
    } else {
      token = getToken();
    }
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    approvedChecks() {
      if (!this.allChecks || !this.allChecks.results) {
        return [];
      }
      return this.allChecks.results.filter(check => {
        return check.status === 3 && check.guaranteed_prize === null;
      });
    },
    isFormValid() {
      return (
          this.password.length >= 6 &&
          this.password_check.length >= 6 &&
          this.password === this.password_check
      );
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    selectPrize(prize) {
      this.selectedPrizeId = prize;
    },
    confirmPrize() {
      if (this.selectedPrizeId) {
        this.patchReceiptPrize(this.currentCheckId, this.selectedPrizeId.id);
      }
    },
    async patchReceiptPrize(checkId, prizeId) {
      try {
        await patchReceiptPrize(checkId, prizeId);  // Отправка PATCH запроса
        this.showChoizePrizePopup = false;
        // Обновляем призы
        await this.fetchWinningPrizes();
        await this.fetchAllUsersCheck();
      } catch (error) {
        console.error('Ошибка при выборе приза:', error);
      }
    },
    async fetchUsersCheck() {
      try {
        const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

        // Добавляем новые чеки к существующим
        this.checks.results.push(...checksData.results);

        // Обновляем значение next
        this.checks.next = checksData.next;
      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    async fetchAllUsersCheck() {
      try {
        const checksData = await getAllUserChecks();
        this.allChecks = checksData;
      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    handleChatBotButtonClick() {
      this.$sendMetrik('reachGoal', 'Bot_Click');
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    loadMoreChecks() {
      if (this.checks.next) {
        this.checkOffset += 20;
        this.fetchUsersCheck();
      }
    },
    goToGamePage() {
      this.$router.push('/game');
      setTimeout(function(){
        window.scrollTo({
          top: '0',
          behavior: 'smooth'
        });
      }, 200)
    },
    initializeOpenState() {
      this.isOpen = [...this.prizes, ...this.checks.results].map(() => false);
    },
    openBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = true; // Установка состояния для открытия блока
      });
    },
    closeBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = false; // Установка состояния для закрытия блока
      });
    },
    getImageSrc(imageUrl) {
      // You can add any logic here to manipulate or format the image URL if needed
      return imageUrl;
    },
    getStatusText(status) {
      return this.statusTextMap[status] || 'Unknown';
    },
    getStatusClass(status) {
      return {
        'status-loaded': status === 0,
        'status-pending': status === 1,
        'status-approved': status === 3,
        'status-rejected': status === 4,
        'status-won': status === 5,
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        this.$sendMetrik('reachGoal', 'Check_Click_PersonalArea');
        qrElement.click();
      }
    },
    async fetchWinningPrizes() {
      try {
        const prizes = await getWinningPrizes();
        this.prizes = prizes.results;
      } catch (error) {
        console.error('Error fetching winning prizes:', error);
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.last_name = input;
    },
    openAccountDetailsPopup() {
      this.showAccountDetailsPopup = true;
      this.responseError = '';
    },
    async changeAccountPassword() {
      try {
        const { password, password_check } = this;

        // Проверка полей
        this.errors = {};
        if (password.length < 6) {
          this.errors.password = 'Пароль должен быть не менее 6 символов';
        }
        if (password_check.length < 6) {
          this.errors.password_check = 'Подтверждение пароля должно быть не менее 6 символов';
        }
        if (password !== password_check) {
          this.errors.password_check = 'Пароли не совпадают';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение пароля
        await changePassword(password);

        // Успешное завершение действий, например, закрытие модального окна или уведомление
        this.closeAccountChangePasswordPopup();
        this.openAccountChangePasswordPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    openAccountChangePasswordPopup() {
      this.showAccountChangePasswordPopup = true;
      this.responseError = '';
    },
    async openChoicePrizePopup(checkId) {
      this.currentCheckId = checkId;

      try {
        const prizesData = await getPrizes();

        // Проверяем, что prizesData является массивом
        if (Array.isArray(prizesData)) {
          // Фильтруем призы по типу 0 (гарантированные призы)
          this.garantPrizes = prizesData.filter(prize => prize.type === 0);
        } else {
          this.garantPrizes = [];
        }
      } catch (error) {
        this.garantPrizes = [];
      }

      this.showChoizePrizePopup = true;
    },
    closeChoizePrizePopup() {
      this.showChoizePrizePopup = false;
      this.selectedPrizeId = null;
    },
    closeAccountChangePasswordPopup() {
      this.showAccountChangePasswordPopup = false;
    },
    openAccountChangePasswordPopupSuccess() {
      this.showAccountChangePasswordSuccess = true;
      this.responseError = '';
    },
    closeAccountDetailsPopup() {
      this.showAccountDetailsPopup = false;
    },
    closeAccountChangePasswordSuccess() {
      this.showAccountChangePasswordSuccess = false;
    },
    openAccountDetailsPopupSuccess() {
      this.showAccountDetailsSuccess = false;
      this.responseError = '';
    },
    closeAccountDetailsSuccess() {
      this.showAccountDetailsSuccess = false;
    },
    async changeAccountDetails() {
      try {
        const { first_name, last_name, email } = this.accountUser;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await changeProfile(first_name, last_name, email);

        // Закрываем окно регистрации
        this.closeAccountDetailsPopup();
        this.openAccountDetailsPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    handleScroll() {
      const AccountSections = document.querySelectorAll('.account-section');

      let currentSectionIndex = -1;

      AccountSections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeAccountSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.accountSections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.accountSections.length; i++) {
          this.accountSections[i].scrolled = false;
        }
      } else {
        this.activeAccountSection = 0;
        this.accountSections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeAccountSectionIndex = index;
    },
    isActive(index) {
      return this.activeAccountSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeAccountSectionIndex;
    },
  },
  async created() {
    try {
      const [mainSettingsData] = await Promise.all([
        getMainSettings()
      ]);
      this.mainSettings = mainSettingsData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
};
</script>

<style>
/* Ваши стили */
</style>