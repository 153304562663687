<template>

  <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened, 'tg-home-class': isTGHomePage }]">
    <div class="mobile-nav__header">
      <button @click="toggleMobileNav" class="button mobile-button">
        <img src="@/assets/img/icons/close.svg" alt="">
      </button>
    </div>

    <div class="mobile-nav__links">
      <a href="/#how-register" @click="toggleMobileNav">Как принять участие</a>
      <a href="/#prizes" @click="toggleMobileNav">Призы</a>
      <a href="/#winners" @click="toggleMobileNav" >Победители</a>
      <a href="/recipes" @click="toggleMobileNav" >Рецепты</a>
      <a href="/#faq" @click="toggleMobileNav" >FAQ</a>

      <div class="mobile-nav__btns">
        <button v-if="!user" @click="openLoginPopup" class="button button-rose">Загрузить чек</button>
        <button v-if="!user" @click="openLoginPopup" class="button button-login">Войти</button>
        <template v-else>
          <button class="button button-white button-account" @click="redirectToPrivatePage">{{ user.first_name }} {{ user.last_name_initial }}</button>
          <button @click="logout" class="button button-logout"></button>
        </template>
      </div>
      <div class="mobile-nav__btn-group">
        <button v-if="user" @click="openLoginPopup" class="button button-rose">Загрузить чек</button>
        <a href="https://ouglechepole.ru/" target="_blank" class="button button-white">Основной сайт</a>
      </div>
    </div>

    <div class="mobile-nav__footer">
      <div class="social-links">
        <a href="https://t.me/ouglechepole" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-tg.svg" alt="">
        </a>
        <a href="https://vk.com/organicforlife" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-vk.svg" alt="">
        </a>
        <a href="https://www.youtube.com/channel/UCOOyXZiZyGRukJN3ZO8GPCA" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-yt-white.svg" alt="">
        </a>
        <a href="https://rutube.ru/channel/35518097/" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-rutube-white.svg" alt="">
        </a>
        <a href="https://dzen.ru/uglicheese" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-dzen-white.svg" alt="">
        </a>
      </div>
    </div>
  </div>

  <header :class="[' ', { 'scrolled': isHeaderScrolled, 'tg-home-class': isTGHomePage }]">
    <div class="header">
      <div class="header-logos">
        <a href="/">
          <img src="@/assets/img/ui/logo.svg" alt="">
        </a>
      </div>
      <div class="header-nav">
        <a href="/#how-register">Как принять участие</a>
        <a href="/#prizes">Призы</a>
        <a href="/#winners">Победители</a>
        <a href="/recipes">Рецепты</a>
        <a href="/#faq">FAQ</a>
      </div>
      <div class="header-user">
        <a href="https://ouglechepole.ru/" class="button button-white" target="_blank">Наш основной сайт</a>
        <button v-if="!user" @click="openLoginPopup" class="button button-green">
          Загрузить чек
        </button>
        <template v-else>
          <button class="button button-green" @click="triggerQrScan">
            Загрузить чек
          </button>
        </template>

        <button v-if="!user" @click="openLoginPopup" class="button button-login">
          Войти
        </button>
        <template v-else>
          <button class="button button-white" @click="redirectToPrivatePage">
            Личный кабинет
          </button>
          <button @click="logout" class="button button-logout"></button>
        </template>
      </div>

      <button @click="toggleMobileNav" class="mobile-button button">
        <img src="@/assets/img/icons/mobile-menu.svg" alt="">
      </button>
    </div>
  </header>

  <main :class="{'tg-home-class': isTGHomePage}">
    <router-view></router-view>
  </main>

  <footer :class="{'tg-home-class': isTGHomePage}">
    <div class="footer">
      <div class="footer__left">
        <div class="footer-nav">
          <div class="footer-nav__logo">
            <a href="/">
              <img src="@/assets/img/ui/logo.svg" alt="">
            </a>
          </div>
          <div class="footer-nav__links">
            <span @click="openQuestionPopup">Обратная связь</span>
            <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
            <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">Политика конфиденциальности</a>
          </div>
        </div>
        <div class="footer-copyright">
          <p>Для участия в Акции необходимо в период с 15 сентября 2024 г. по 30 ноября 2024 г. приобрести продукцию, выпускаемую под товарным знаком «Углече Поле», продаваемых в магазинах на территории города Москвы и Московской области, а также в интернет-магазине organicmarket.ru.</p>
          <p>Общий срок проведения Акции (включая сроки выдачи призов): с 15 сентября 2024 г по 31 декабря 2024 г. (включительно). Количество призов ограничено. Призы могут отличаться от изображений на рекламных материалах. Информация об организаторе акции, сроках и правилах ее проведения, количестве призов, сроках, месте и порядке вручения призов размещена на сайте ouglechepole.promo Организатор акции – ООО «Мирвест», ИНН 7708769614</p>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer-social">
          <div class="footer-social__title">
            <span @click="openQuestionPopup">Обратная связь</span>
          </div>
          <div class="footer-social__links">
            <a href="https://t.me/ouglechepole" target="_blank">
              <img src="@/assets/img/icons/social-tg-dark.svg" alt="">
            </a>
            <a href="https://vk.com/organicforlife" target="_blank">
              <img src="@/assets/img/icons/social-vk-dark.svg" alt="">
            </a>
            <a href="https://www.youtube.com/channel/UCOOyXZiZyGRukJN3ZO8GPCA" target="_blank">
              <img src="@/assets/img/icons/social-yt.svg" alt="">
            </a>
            <a href="https://rutube.ru/channel/35518097/" target="_blank">
              <img src="@/assets/img/icons/social-rutube.svg" alt="">
            </a>
            <a href="https://dzen.ru/uglicheese" target="_blank">
              <img src="@/assets/img/icons/social-dzen.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <!-- Popup для входа -->
  <div v-if="showLoginPopup" class="popup">
    <div class="popup__overlay"></div>
    <div class="popup__window">
      <button @click="closeLoginPopup" class="popup__close"></button>
      <div class="popup__title">Авторизация</div>
      <div class="popup__content">
        <form @submit.prevent="login" autocomplete="off">
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="login">Логин</label>
              <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask>
              <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="password">Пароль</label>
              <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
              />
              <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
              <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
              <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
            </div>
          </div>
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-green button-green--big">Войти</button>
            </div>
          </div>
          <div class="field-row field-row-account">
            <div class="field-item">
              Нет аккаунта?
              <button class="button button-white button-white-bordered" @click="openRegisterPopup">Регистрация</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Popup для регистрации -->
  <div v-if="showRegisterPopup" class="popup">
    <div class="popup__overlay"></div>
    <div class="popup__window">
      <button @click="closeRegisterPopup" class="popup__close"></button>
      <div class="popup__title">Регистрация</div>
      <div class="popup__content">
        <form @submit.prevent="register" autocomplete="off">
          <!-- Поле Имя -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="reg-name">Имя</label>
              <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                     autocomplete="off"
                     @blur="clearError('first_name')"
                     :class="{ 'is-error': errors.first_name }">
              <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
            </div>
          </div>
          <!-- Поле Фамилия -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="last-name">Фамилия</label>
              <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                     autocomplete="off"
                     @blur="clearError('last_name')"
                     :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
            </div>
          </div>
          <!-- Поле Номер телефона -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="reg-phone">Номер телефона</label>
              <div class="field-row" data-cols="1">
                <div class="field-item">
                  <input
                      type="text"
                      maxlength="18"
                      id="reg-phone"
                      v-model="registerForm.phone_number"
                      name="phone_number"
                      autocomplete="off"
                      @input="formatPhoneNumber"
                      @blur="clearError('phone_number')"
                      :class="{ 'is-error': errors.phone_number }"
                      v-mask>
                  <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Поле Email -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="reg-email">Email</label>
              <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                     autocomplete="off"
                     @blur="clearError('email')"
                     :class="{ 'is-error': errors.email }">
              <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
            </div>
          </div>
          <!-- Чекбокс согласия с обработкой персональных данных -->
          <div class="field-row" data-cols="1">
            <div class="field-item field-item-checkbox">
              <label for="reg-personality">
                <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                <span>Согласен(на) <a class="link" v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">с обработкой персональных данных</a></span>
              </label>
              <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
            </div>
          </div>
          <!-- Чекбокс согласия с правилами акции -->
          <div class="field-row" data-cols="1">
            <div class="field-item field-item-checkbox">
              <label for="reg-rules">
                <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">с правилами акции</a></span>
              </label>
              <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
            </div>
          </div>
          <!-- Кнопка для отправки формы регистрации -->
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-green button-green--big">Регистрация</button>
            </div>
          </div>
          <!-- Кнопка для переключения на окно входа -->
          <div class="field-row field-row-account">
            <div class="field-item">
              Уже есть аккаунт?
              <button class="button button-white button-white-bordered" @click="switchToLogin">Войти</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showRegisterPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
    <div class="popup__window">
      <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
      <div class="popup__content">
        <div class="success-message">
          Спасибо за регистрацию.
          <br>Пароль для входа отправлен в&nbsp;смс
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для вопроса -->
  <div v-if="showQuestionPopup" class="popup">
    <div class="popup__overlay" @click="closeQuestionPopup"></div>
    <div class="popup__window">
      <button @click="closeQuestionPopup" class="popup__close"></button>
      <div class="popup__title">Обратная связь</div>
      <div class="popup__content">
        <form @submit.prevent="submitQuestion" autocomplete="off">
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="feedback-name">Имя</label>
              <input
                  type="text"
                  v-model="questionForm.name"
                  id="feedback-name"
                  name="name"
                  @input="removeSpacesName"
                  :disabled="isNameFieldDisabled" />
              <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="feedback-email">Email</label>
              <input
                  type="text"
                  v-model="questionForm.email"
                  id="feedback-email"
                  name="email"
                  :disabled="user && !!user.email" />
              <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="feedback-message">Сообщение</label>
              <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
              <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item field-item-checkbox">
              <label for="feedback-personality">
                <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                <span>Согласен(на) <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank" class="link">с обработкой персональных данных</a></span>
              </label>
              <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
            </div>
          </div>
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-green button-green--big">Отправить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showQuestionPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
    <div class="popup__window">
      <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
      <div class="popup__content">
        <div class="success-message">
          Ваше обращение принято
          <br>Мы свяжемся с вами в ближайшее время
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для восстановления пароля -->
  <div v-if="showResetPasswordPopup" class="popup">
    <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
    <div class="popup__window">
      <button @click="closeResetPasswordPopup" class="popup__close"></button>
      <div class="popup__title">Восстановление пароля</div>
      <div class="popup__content">
        <form @submit.prevent="submitResetPassword" autocomplete="off">
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="reg-phone">Номер телефона</label>
              <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                     :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
              <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
            </div>
          </div>
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-green button-green--big">Отправить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showResetPasswordSuccess" class="popup">
    <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
    <div class="popup__window">
      <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
      <div class="popup__title">
        Восстановление пароля
      </div>
      <div class="popup__content">
        <div class="success-message">
          Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для временного закрытия ЛК -->
  <div v-if="AccountPopup" class="popup">
    <div class="popup__overlay" @click="closeAccountPopup"></div>
    <div class="popup__window">
      <button @click="closeAccountPopup" class="popup__close"></button>
      <div class="popup__title">
        Личный кабинет
      </div>
      <div class="popup__content">
        <div class="success-message">
          Личный кабинет временно недоступен.
          <br>Мы уже знаем о проблеме и работаем над ее устранением
        </div>
      </div>
    </div>
  </div>

  <div style="position: absolute; bottom: 100%; overflow: hidden;">
    <div id="apm-widget"></div>
  </div>

</template>

<script>
/* global qrWidget */

import { fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword } from './api';
import metrikaMixin from "@/mixins/metrikaMixin";

export default {
  name: 'App',
  mixins: [metrikaMixin],
  data() {
    return {
      observer: null,
      isNameFieldDisabled: false,
      isHeaderScrolled: false,
      scrollThreshold: 10,
      rootUrl: window.location.origin,
      userId: '',
      uuid: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    this.checkAuthentication();
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.fetchTimerData();
  },
  watch: {
    user(newUser) {
      if (!newUser) {
        this.$nextTick(() => {
          this.questionForm.name = '';
        });
      }
    }
  },
  mounted() {
    this.addTooltipEvents();
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.uuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);

    // Create a mutation observer to detect DOM changes
    this.observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Check if the button with the ID 'apm-submit-photos-button' is added
          const submitButton = document.getElementById('apm-submit-photos-button');
          if (submitButton) {
            // Add event listener to the button
            submitButton.addEventListener('click', () => {
              // Add a class to the body
              document.body.classList.add('photos-submitted');
            });
          }
        }
      }
    });

    // Start observing the body for child elements being added
    this.observer.observe(document.body, {
      childList: true, // Look for changes in child elements
      subtree: true,   // Watch the entire subtree for changes
    });

  },
  beforeUnmount() {
    this.removeTooltipEvents();
    // Disconnect the observer when the component is destroyed to avoid memory leaks
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isTGHomePage() {
      return this.$route.name === 'TGHomePage' || this.$route.name === 'TGPrivatePage' || this.$route.name === 'TGUploadCheck';
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    addTooltipEvents() {
      document.addEventListener('mouseover', this.handleMouseOver);
      document.addEventListener('mouseout', this.handleMouseOut);
    },
    removeTooltipEvents() {
      document.removeEventListener('mouseover', this.handleMouseOver);
      document.removeEventListener('mouseout', this.handleMouseOut);
    },
    handleMouseOver(event) {
      let target = event.target;
      let tooltipHtml = target.dataset.tooltip;

      if (!tooltipHtml) return;

      this.tooltip = document.createElement('div');
      this.tooltip.className = 'tooltip';
      this.tooltip.innerHTML = tooltipHtml;
      document.body.appendChild(this.tooltip);

      let coords = target.getBoundingClientRect();
      let left = coords.left + (target.offsetWidth - this.tooltip.offsetWidth) / 2;
      if (left < 0) left = 0;

      let top = coords.bottom + 5; // Изменено для появления под элементом
      if (top + this.tooltip.offsetHeight > window.innerHeight) {
        top = coords.top - this.tooltip.offsetHeight - 5; // Показывать над элементом, если не хватает места снизу
      }

      this.tooltip.style.left = left + 'px';
      this.tooltip.style.top = top + 'px';
    },
    handleMouseOut() {
      if (this.tooltip) {
        this.tooltip.remove();
        this.tooltip = null;
      }
    },

    formatPhoneNumber() {
      let phone = this.registerForm.phone_number.replace(/\D/g, '');  // Удаляем все нечисловые символы
      if (!phone.startsWith('7')) {
        phone = '7' + phone;  // Добавляем '7' в начале, если его нет
      }
      this.registerForm.phone_number = `+${phone}`;  // Форматируем как +7
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: '523058c9-f7d7-43ed-8b7a-e7d3573b5f76',
          userUuid: this.uuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'Check_Download_Success');
              document.body.classList.remove('photos-submitted');
            },
            onReceiptSentError: () => {
              document.body.classList.remove('photos-submitted');
            }
          }
        });
      }
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.showQuestionPopup = true;
      this.isNameFieldDisabled = this.user && !!this.user.first_name;
    },
    closeQuestionPopup() {
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = true;
      this.$sendMetrik('reachGoal', 'Feedback_SentForm_Success');
    },
    closeQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
    },
    closeResetPasswordPopup() {
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    async submitQuestion() {
      try {
        // Валидация формы перед отправкой
        this.feedbackErrors = {};
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.questionForm.email)) {
          this.feedbackErrors.email = 'Введите корректный email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return; // Если есть ошибки валидации, не отправляем запрос
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text
        });

        // Закрыть попап после успешной отправки
        this.openQuestionPopupSuccess();
        this.closeQuestionPopup();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;

      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenURL = urlParams.get('token');
      let token = '';
      if (tokenURL) {
        token = tokenURL;
      } else {
        token = getToken();
      }
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.uuid = userInfo.uuid;
          this.initQrWidget();
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если акция уже завершилась
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    closeLoginPopup() {
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
    },
    closeRegisterPopup() {
      this.$sendMetrik('reachGoal', 'SignUp_Cancel');
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.$sendMetrik('reachGoal', 'SignUp_SentForm_Success');
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.uuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.initQrWidget();
              this.$router.push('/private');
              this.$sendMetrik('reachGoal', 'SignIn_SentForm_Success');
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email);

        // Закрываем окно регистрации
        this.closeRegisterPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
    },
    logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = '';
      }
      this.toggleMobileNav();
      removeToken();

      this.user = null;

      this.$nextTick(() => {
        this.questionForm = {
          name: '',
          email: '',
          text: '',
          agree_personal_data: false
        };

        // Принудительное обновление компонента
        this.$forceUpdate();
        window.location.href = '/';
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/App.scss';
</style>